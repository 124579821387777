<template>
    <select v-model="currentValue" class="form-control" @change="onChange" :disabled="disabled">
        <option v-for="option in types" :value="option">
            {{ option.name }}
        </option>
    </select>
</template>

<script>
    import { INVENTORY_ACTS_ENDPOINT } from '@utils/endpoints';

    export default {
        name: "InventoryActTypePick",
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            value: {},
        },
        data() {
            return {
                types: [],
                currentValue: null,
            }
        },
        watch: {
            value() {
                this.currentValue = this.value
            },
        },
        mounted() {
            this.loadTypes();
        },
        methods: {
            getBaseUrl() {
                return INVENTORY_ACTS_ENDPOINT + '/types';
            },
            loadTypes() {
                this.$http.get(this.getBaseUrl())
                    .then(response => {
                        this.types = response.data;
                    });
            },
            onChange() {
                this.$emit('input', this.currentValue)
            },
        },
    }
</script>

<style scoped>

</style>
