<template>
    <div style="margin-bottom: 75px;" class="act-form inventorying-act-form">
        <errors-bag-list/>
        <b-tabs pills card v-model="tabIndex">
            <b-tab title="Акт" active>
                <div class="card">
                    <b-collapse id="storage-wrapper" v-model="storageOpened">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <b-form-group description="Склад" :class="{'has-errors': hasError('act.storage_id')}">
                                        <storage-picker :disabled="created" v-model="act.storage"></storage-picker>
                                    </b-form-group>
                                </div>
                                <div class="col-6">
                                    <b-form-group description="Тип инвентаризации" :class="{'has-errors': hasError('act.inventory_act_type_id')}">
                                        <inventory-act-type-pick :disabled="created" v-model="act.type"></inventory-act-type-pick>
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6" style="padding-right: .1rem">
                                    <small v-if="created && !!act.actualShift">Дата проведения: {{moment(act.date).format('DD.MM.YYYY HH:mm')}}</small>
                                    <b-form-group v-else description="Дата проведения">
                                        <input :disabled="true" type="datetime-local" class="form-control" :value="act.date">
                                    </b-form-group>
                                </div>
                                <div class="col-6">
                                    <small v-if="created && !!act.movements_processed_at">Дата пересчета остатков: {{moment(act.movements_processed_at).format('DD.MM.YYYY HH:mm')}}</small>
                                    <small v-else-if="created && !act.movements_processed_at" class="alert-danger">ОСТАТКИ НЕ ПЕРЕСЧИТАНЫ!</small>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                    <b-card>
                        <b-card-body>
                            <div class="row">
                                <div class="col-3 text-right">
                                    <b-button
                                        v-show="!created"
                                        variant="success"
                                        style="font-size: 12px;"
                                        @click="addItem($event, false, false)"
                                    >+</b-button>
                                </div>
                                <div class="col-6 text-right">
                                    <b-button
                                        variant="danger"
                                        v-show="act.storage"
                                        style="font-size: 12px;"
                                        @click="fillItemsWithNull"
                                        :disabled="disabled || !(!!act.storage.name && act.type && (act.type.code === ACT_TYPE_CODE_FULL || act.type.code === ACT_TYPE_CODE_MONTHLY))"
                                    >
                                        <div class="d-none d-md-block d-sm-none">Обнулить</div>
                                        <div class="d-md-none d-sm-block"><i class="fas fa-arrow-down"></i> 0</div>
                                    </b-button>
                                </div>
                                <div class="col-3 text-right">
                                    <h4>
                                        <b-badge v-if="act.status === ACT_STATUS_DRAFT" variant="info">Ожидает подтверждения</b-badge>
                                        <b-badge v-else-if="act.status === ACT_STATUS_CONFIRMED" variant="success">Подтвержден</b-badge>
                                        <b-badge v-else-if="act.status === ACT_STATUS_CANCELED" variant="danger">Отменен</b-badge>
                                    </h4>
                                </div>
                            </div>
                        </b-card-body>
                    </b-card>
                    <table id="inventory-act-items" class="table b-table b-table-fixed">
                        <thead>
                            <tr>
                                <th class="nomenclature">Номенклатура</th>
                                <th class="nomenclature-lot">Партия</th>
                                <th class="manufacture-date">Дата производства</th>
                                <th class="expiration-date">Срок годности</th>
                                <th class="fact-count">Факт. кол-во</th>
                                <th class="report-count">Кол-во по системе</th>
                                <th class="measure-unit">Ед. изм.</th>
                                <th class="actions">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in act.items" :key="item.id" :class="{ 'blocked': item.blocked }">
                                <td class="nomenclature">
                                    <template v-if="isItemNomenclatureDisabled(item)">
                                        {{ item.nomenclature ? item.nomenclature.name : "Номенклатура" }}
                                    </template>
                                    <nomenclature-input
                                        v-else
                                        placeholder="Номенклатура"
                                        v-model="item.nomenclature"
                                        @input="updateStorageCount(item)"
                                    />
                                </td>
                                <td class="nomenclature-lot" :class="{ 'blocked': isItemLotDisabled(item), empty: !item.nomenclatureLot }">
                                    <template v-if="isItemLotDisabled(item)">
                                        <template v-if="item.nomenclatureLots && item.nomenclatureLots.length > 1">
                                            {{ item.nomenclatureLots.map(lot => lot.id).join(', ') }}
                                        </template>
                                        <template v-else>
                                            {{ item.nomenclatureLot ? item.nomenclatureLot.id : "Партия" }}
                                        </template>
                                    </template>
                                    <nomenclature-lot-input
                                        v-else
                                        placeholder="Партия"
                                        :allowWithoutLot="true"
                                        v-model="item.nomenclatureLot"
                                        @input="updateStorageCount(item)"
                                        :availableItems="nomenclatureLots"
                                        :nomenclatureId="item.nomenclature ? item.nomenclature.id : null"
                                    />
                                </td>
                                <td class="manufacture-date" :class="{ empty: !item.expirationDate }">
                                    <template v-if="isItemManufactureDateDisabled(item)">
                                        <template v-if="item.nomenclatureLots && item.nomenclatureLots.length > 1">
                                            &mdash;
                                        </template>
                                        <template v-else>
                                            {{ item.manufactureDate ? moment(item.manufactureDate).format('DD.MM.YYYY') : 'дд.мм.гггг' }}
                                        </template>
                                    </template>
                                    <date-time-picker
                                        v-else
                                        locale="ru"
                                        :format="null"
                                        input-size="sm"
                                        position="bottom"
                                        :no-label="true"
                                        :no-header="true"
                                        :no-button="true"
                                        :no-clear-button="true"
                                        :auto-close="true"
                                        :only-date="true"
                                        label="дд.мм.гггг"
                                        formatted="DD.MM.YYYY"
                                        v-model="item.manufactureDate"
                                        :id="'manufacture-date-' + index"
                                        :max-date="item.expirationDate ? moment(item.expirationDate).format('YYYY-MM-DD') : null"
                                    />
                                </td>
                                <td class="expiration-date" :class="{ empty: !item.manufactureDate }">
                                    <template v-if="isItemExpirationDateDisabled(item)">
                                        <template v-if="item.nomenclatureLots && item.nomenclatureLots.length > 1">
                                            &mdash;
                                        </template>
                                        <template v-else>
                                            {{ item.expirationDate ? moment(item.expirationDate).format('DD.MM.YYYY') : 'дд.мм.гггг' }}
                                        </template>
                                    </template>
                                    <date-time-picker
                                        v-else
                                        locale="ru"
                                        :format="null"
                                        input-size="sm"
                                        position="bottom"
                                        :no-label="true"
                                        :no-header="true"
                                        :no-button="true"
                                        :no-clear-button="true"
                                        :auto-close="true"
                                        :only-date="true"
                                        label="дд.мм.гггг"
                                        formatted="DD.MM.YYYY"
                                        v-model="item.expirationDate"
                                        :id="'expiration-date-' + index"
                                        :min-date="item.manufactureDate ? moment(item.manufactureDate).format('YYYY-MM-DD') : null"
                                    />
                                </td>
                                <td class="fact-count">
                                    <button :disabled="disabled || !actInEditableStatus" class="btn" alt="Обнулить" @click="setItemCountToZero(index)">
                                        0 <i class="fas fa-angle-right"></i>
                                    </button>
                                    <b-form-input
                                        autocomplete="off"
                                        v-model="item.count"
                                        :disabled="disabled || !actInEditableStatus"
                                        :formatter="factCountFormatter"
                                        @focus="item.count = factCountFocused(item.count)"
                                        @blur="item.count = factCountBlured(item.count)"
                                    />
                                </td>
                                <td class="report-count">
                                    {{ item.system_count }}
                                </td>
                                <td class="measure-unit">
                                    {{ item.nomenclature && item.nomenclature.measureUnit && item.nomenclature.measureUnit.name || 'шт.' }}
                                </td>
                                <td :class="{ 'actions': true, 'actions-disabled': !isItemDeletable(item) }">
                                    <b-button
                                        variant="outline-danger"
                                        @click="removeItem(index)"
                                        v-show="isItemDeletable(item)"
                                    >
                                        <i class="fas fa-trash" aria-hidden="true" />
                                    </b-button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </b-tab>
            <b-tab :title="(act.comment ? '* ' : '') + 'Проверили' + (act.revisions ? ` (${act.revisions.length})` : '')">
                <b-card>
                    <b-form-group description="Проверили">
                        <div v-for="revision in act.revisions">{{revision.user.username}} ({{formatDate('DD.MM.Y HH:mm', revision.created_at)}});</div>
                    </b-form-group>
                    <b-form-group>
                    <button v-if="act.id" :disabled="disabled" @click="review" class="btn btn-sm btn-default">Ознакомился</button>
                        </b-form-group>
                    <b-form-group description="Комментарий">
                        <textarea :disabled="disabled" v-model="act.comment" class="form-control" rows="12"></textarea>
                    </b-form-group>
                </b-card>
            </b-tab>
        </b-tabs>

        <control-panel v-show="act && tabIndex === 0">
            <b-button variant="default" @click="$router.go(-1)">Вернуться к списку</b-button>
            <b-button variant="success" v-show="!created" @click="addItem($event, false, false)">+</b-button>
            <b-button v-show="showCancelButton" variant="danger" @click="confirm('После отмены редактирование будет невозможно. Вы уверены?') && cancelAct()">Отменить</b-button>
            <b-button v-show="showConfirmButton" variant="primary" @click="confirm('После подтверждения редактирование будет невозможно. Вы уверены?') && confirmAct()">Подтвердить</b-button>
            <b-button variant="success" :disabled="isSaveButtonDisabled" @click="save()">
                <i v-if="saving" class="fas fa-spinner fa-spin"></i>
                Сохранить
            </b-button>
            <b-button variant="default" @click="print">Печать</b-button>
            <button v-if="act.id" @click="downloadReport()" class="btn btn-outline-default"><i class="fas fa-download"></i></button>
        </control-panel>
    </div>
</template>

<script>
    import moment from 'moment';
    import ErrorsBag from '../../utils/errorsBag';
    import ControlPanel from '../_common/ControlPanel';
    import ErrorsBagList from '../_common/ErrorsBagList';
    import StoragePicker from "../_common/StoragePicker";
    import NomenclatureInput from "../_common/NomenclatureInput";
    import NomenclatureLotInput from "../_common/NomenclatureLotInput";
    import InventoryActTypePick from "./InventoryActTypePick";
    import SuccessMessage from "../_common/SuccessMessage";
    import DateTimePicker from "vue-ctk-date-time-picker";
    import { ROLE_ACCOUNTANT, ROLE_STOCKMAN_SENIOR, ROLE_STOCKMAN } from '@utils/Roles';
    import { INVENTORY_ACTS_ENDPOINT, STORAGE_REPORTS_ENDPOINT } from '@utils/endpoints';
    import CSV from '../../utils/csv';

    import {GetItemByBarcodeMixin} from '@mixins';

    const ACT_TYPE_CODE_FULL = "full";
    const ACT_TYPE_CODE_PARTIAL = "partial";
    const ACT_TYPE_CODE_MONTHLY = "monthly";

    const ACT_STATUS_DRAFT = "draft";
    const ACT_STATUS_PENDING = "pending";
    const ACT_STATUS_CONFIRMED = "confirmed";
    const ACT_STATUS_CANCELED = "canceled";

    export default {
        name: "Form",
        components: {
            ErrorsBagList,
            StoragePicker,
            DateTimePicker,
            ControlPanel,
            NomenclatureInput,
            NomenclatureLotInput,
            InventoryActTypePick,
            SuccessMessage
        },
        mixins: [
            GetItemByBarcodeMixin,
        ],
        computed: {
            actInEditableStatus() {
                return [ACT_STATUS_DRAFT, null].includes(this.act.status)
            },
            created() {
                return !!this.act.id;
            },
            disabled() {
                return !!this.act.blocked || this.loading || this.saving;
            },
            isSaveButtonDisabled() {
                if (this.disabled) {
                    return true;
                }
                if (!this.act.storage.name) {
                    return true;
                }
                if (!this.act.type) {
                    return true;
                }
                return !this.act.items.every(this.isItemValid);
            },
            showConfirmButton() {
                if (this.created
                    && this.act.status === ACT_STATUS_DRAFT
                    && (
                        this.$auth.user().roles.some(role => [ROLE_ACCOUNTANT].includes(role))
                        || (
                            this.$auth.user().roles.some(role => [ROLE_STOCKMAN, ROLE_STOCKMAN_SENIOR].includes(role))
                            && this.act.type
                            && [ACT_TYPE_CODE_FULL, ACT_TYPE_CODE_PARTIAL].includes(this.act.type.code)
                        )
                )) {
                    return true
                }

                return false
            },
            showCancelButton() {
                return this.created && this.$auth.user().roles.some(role => [ROLE_ACCOUNTANT].includes(role)) && this.act.status === ACT_STATUS_DRAFT;
            }
        },
        data() {
            return {
                ACT_TYPE_CODE_FULL,
                ACT_TYPE_CODE_MONTHLY,
                ACT_STATUS_DRAFT,
                ACT_STATUS_PENDING,
                ACT_STATUS_CONFIRMED,
                ACT_STATUS_CANCELED,

                tabIndex: null,
                act: {
                    id: null,
                    storage: {},
                    date: null,
                    type: null,
                    comment: '',
                    items: [],
                    blocked: false,
                    status: null,
                },
                errors: {},
                storageOpened: true,
                nomenclatureLots: [],
                loading: true,
                saving: false
            };
        },
        created() {
            this.act.date = moment().format('YYYY-MM-DDTHH:mm');
        },
        mounted() {
            this.fetchAct(this.$route.params.id || null);
            this.$root.listenToBarcodeScanning(this);
        },
        watch: {
            'act.storage.id'() {
                if (!this.loading || !this.created) {
                    this.updateReport();
                }
            },
            'act.type'(newType, oldType) {
                if (!this.loading || !this.created) {
                    const oldTypeCode = oldType && oldType.code;
                    const newTypeCode = newType && newType.code;
                    if ([ACT_TYPE_CODE_PARTIAL].includes(newTypeCode)) {
                        this.fillNomenclatureLots();
                    }
                    if ([ACT_TYPE_CODE_FULL, ACT_TYPE_CODE_MONTHLY].includes(newTypeCode)) {
                        this.fillItems();
                    }
                    if ([ACT_TYPE_CODE_FULL, ACT_TYPE_CODE_MONTHLY].includes(oldTypeCode) &&
                       ![ACT_TYPE_CODE_FULL, ACT_TYPE_CODE_MONTHLY].includes(newTypeCode)
                    ) {
                        this.clearItems();
                    }
                }
            }
        },
        methods: {
            moment,
            getStorageReport(storageId, dateTo) {
                let filters = {
                    storageIds: [storageId]
                };

                if (dateTo) {
                    filters.dateTo = dateTo;
                }

                return this.$http.get(STORAGE_REPORTS_ENDPOINT, {
                    params: {
                        filters: filters,
                        with: [
                            'nomenclature',
                            'nomenclature.measureUnit',
                            'nomenclatureLot'
                        ],
                        allow_non_1c_nomenclature: 1,
                    }
                });
            },
            updateReport() {
                if (!!this.act.storage.id) {
                    let dateTo = null;

                    if (!!this.act.id && !!this.act.date) {
                        dateTo = moment(this.act.date).subtract({seconds: 1}).format("YYYY-MM-DD HH:mm:ss");
                    }

                    this.report = [];
                    this.loading = true;
                    this.getStorageReport(this.act.storage.id, dateTo)
                        .then(response => {
                            this.report = response.data.data
                        })
                        .then(() => {
                            this.afterReportUpdated();
                        })
                        .catch(response => {
                            ErrorsBag.discard();
                            ErrorsBag.fill(response.body);
                            this.$toast.error('Есть ошибки! Ознакомьтесь со списком вверху страницы');
                            window.scrollTo({top: 0, behavior: 'smooth'});
                        })
                        .finally(() => {
                            this.loading = false;
                        });
                }
            },
            afterReportUpdated() {
                if (!this.disabled) {
                    if (this.act.type && [ACT_TYPE_CODE_FULL, ACT_TYPE_CODE_MONTHLY].includes(this.act.type.code)) {
                        this.fillItems();
                    } else {
                        this.fillNomenclatureLots();
                    }
                }
            },
            reportCountValue(nomenclature, nomenclatureLot = null) {
                if (!nomenclature) {
                    return 0;
                }

                if (this.act.type && this.act.type.code === ACT_TYPE_CODE_MONTHLY) {
                    return parseFloat(
                        this.report
                            .filter(item => item.nomenclature_id === nomenclature.id)
                            .reduce((total, item) => total + item.count, 0)
                            .toFixed(3)
                        )
                }

                let reportItem = this.report.find(i => {
                    if (i.nomenclature_id !== nomenclature.id) {
                        return false
                    }

                    if (nomenclatureLot) {
                        return i.nomenclature_lot_id === nomenclatureLot.id
                    }

                    return i.nomenclature_lot_id === null
                });

                return reportItem ? reportItem.count : 0
            },
            retrieveNonenclatureLots(report) {
                this.nomenclatureLots = [];

                report.map(reportItem => {
                    this.nomenclatureLots.push(reportItem.nomenclatureLot);
                });
            },
            retrieveRests(report) {
                this.clearItems();

                if (this.act.type && this.act.type.code === ACT_TYPE_CODE_MONTHLY) {
                    report = this.groupByNomenclatureId(report);
                }

                report.map(reportItem => {
                    this.addItem({
                        box: {},
                        nomenclature: reportItem.nomenclature,
                        nomenclatureLot: reportItem.nomenclatureLot,
                        nomenclatureLots: reportItem.nomenclatureLots,
                        count: reportItem.count,
                        blocked: true
                    }, true, null, false);
                })
            },
            fetchAct(actId) {
                if (!actId) {
                    this.setNewAct();
                    return;
                }

                this.loading = true;
                this.$http.get(this.getBaseUrl() + '/' + actId)
                    .then(response => {
                        if (response.data.type && response.data.type.code === ACT_TYPE_CODE_MONTHLY) {
                            response.data.items = this.groupByNomenclatureId(response.data.items);
                        }

                        this.act = response.data;
                        this.act.date = moment(this.act.date).format('YYYY-MM-DDTHH:mm');
                        this.act.items.map(item => {
                            item.blocked = true;
                            item.exists = true;
                            if (item.nomenclatureLot) {
                                item.manufactureDate = item.nomenclatureLot.manufacture_date;
                                item.expirationDate = item.nomenclatureLot.expiration_date;
                            }
                        });
                    })
                    .catch(response => {
                        ErrorsBag.discard();
                        ErrorsBag.fill(response.body);
                        this.$toast.error('Есть ошибки! Ознакомьтесь со списком вверху страницы');
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            groupByNomenclatureId(items) {
                items = JSON.parse(JSON.stringify(items))
                return Object
                    .values(items.reduce((result, item) => {
                        const { nomenclature, nomenclatureLot, count, system_count } = item
                        if (!(nomenclature.id in result)) {
                            result[nomenclature.id] = item
                            result[nomenclature.id].count = 0
                            result[nomenclature.id].system_count = 0
                            result[nomenclature.id].nomenclatureLots = []
                        }
                        nomenclatureLot.systemCount = parseFloat(count)
                        result[nomenclature.id].count += parseFloat(count)
                        result[nomenclature.id].nomenclatureLots.push(nomenclatureLot)
                        result[nomenclature.id].system_count += parseFloat(system_count)
                        return result
                    }, {}))
                    .map(item => {
                        item.count = parseFloat(item.count.toFixed(3))
                        item.system_count = parseFloat(item.system_count.toFixed(3))
                        return item
                    })
                    .sort((a, b) => a.nomenclature.name.localeCompare(b.nomenclature.name))
            },
            setNewAct() {
                if (!!this.$route.params.storage) {
                    this.act.storage = this.$route.params.storage;
                }
            },
            fillItemsWithNull() {
                if ( this.act.items.length && !window.confirm( 'Вы уверены, что хотите заполнить факт нулями?' ) ) {
                    return;
                }

                this.act.items.map( item => item.count = 0 );
            },
            fillNomenclatureLots() {
                if (this.report) {
                    this.retrieveNonenclatureLots(this.report);
                }
            },
            fillItems() {
                if (!this.report) {

                    if (!this.act.storage.id) {
                        return;
                    }

                    let dateTo = null;

                    if (!!this.act.id && !!this.act.date) {
                        dateTo = moment(this.act.date).subtract({seconds: 1}).format("YYYY-MM-DD HH:mm:ss");
                    }

                    this.getStorageReport(this.act.storage.id, dateTo).then(response => {
                        this.report = response.data.data;
                        this.retrieveNonenclatureLots(this.report);
                        this.retrieveRests(this.report);
                    })
                } else {
                    this.retrieveNonenclatureLots(this.report);
                    this.retrieveRests(this.report);
                }
            },
            clearItems() {
                if (this.act && this.act.items) {
                    this.act.items = this.act.items.filter(item => !item.blocked);
                } else {
                    this.act.items = [];
                }
            },
            processRests(rests) {
                this.act.items = [];
                rests.map(item => {
                    this.addItem(item, true);
                });
            },
            addItem(
                {
                    box = {name: null},
                    nomenclature = null,
                    nomenclatureLot = null,
                    nomenclatureLots = [],
                    count = 0,
                    blocked = false
                },
                withoutSelect = false,
                withoutLot = null,
                prepend = false
            ) {

                if (!nomenclature) {
                    nomenclature = !!nomenclatureLot ? nomenclatureLot.nomenclature : null;
                }

                let item = {
                    id: Math.ceil(Math.random() * 10000000),
                    exists: false,
                    collapsed: false,
                    withoutLot: withoutLot === null ? !(!!nomenclatureLot) : withoutLot,
                    manufactureDate: nomenclatureLot ? nomenclatureLot.manufacture_date : null,
                    expirationDate: nomenclatureLot ? nomenclatureLot.expiration_date : null,
                    system_count: this.reportCountValue(nomenclature, nomenclatureLot),
                    box,
                    count,
                    nomenclature,
                    nomenclatureLot,
                    nomenclatureLots,
                    blocked
                };

                if (prepend) {
                    this.act.items.unshift(item);
                } else{
                    this.act.items.push(item);
                }
            },
            setItemCountToZero(index) {
                if (this.act && this.act.items && this.act.items[index]) {
                    this.act.items[index].count = 0;
                }
            },
            removeItem(index) {
                if (this.disabled) {
                    return;
                }

                if (this.hasError('act.items.'+index)) {
                    this.removeError('act.items.'+index);
                }
                this.act.items.splice(index, 1);
            },
            print() {
                window.print();
            },
            measureUnit(nomenclature) {
                if (!!nomenclature && !!nomenclature.measureUnit) {
                    return nomenclature.measureUnit.name;
                }

                return '';
            },
            updateStorageCount(item) {
                item.system_count = this.reportCountValue(item.nomenclature, item.nomenclatureLot);
                if (item.nomenclatureLot) {
                    item.manufactureDate = item.nomenclatureLot.manufacture_date;
                    item.expirationDate = item.nomenclatureLot.expiration_date;
                }
            },
            save() {
                if (this.saving) {
                    return;
                }
                this.saving = true;
                this.errors = {};

                let method = !!this.act.id ? 'put' : 'post',
                    url = this.getBaseUrl() + (!!this.act.id ? `/${this.act.id}` : ''),
                    data = this.getDataForSave();

                this.$http[method](url, data, {params: {without_loading: true}})
                    .then(response => {
                        this.$router.push({name: 'InventoryActList'});
                    }, response => this.$root.responseError(response, 'Ошибка!')).finally(() => {
                        this.saving = false;
                    });
            },
            getBaseUrl() {
                return INVENTORY_ACTS_ENDPOINT;
            },
            getReportUrl() {
                return this.getBaseUrl()+`/${this.act.id}/report`;
            },
            downloadReport() {
                this.$http
                    .get( this.getReportUrl() )
                    .then( response => {
                        let name =
                            'Инвентаризация_' +
                            this.act.storage.name.split( ' ' ).join( '_' ) + '_' +
                            moment( this.act.date ).format( 'DD.MM.YYYY HH:mm' );

                        CSV.download( response.data, name );
                    } );
            },
            getDataForSave() {
                let data = {
                    act: {
                        storage_id: this.act.storage.id,
                        inventory_act_type_id: this.act.type.id,
                        comment: this.act.comment,
                        items: []
                    }
                };

                if (!!this.act.id) {
                    data.act.id = this.act.id;
                }

                let items = this.act.items;

                if (this.act.type && this.act.type.code === ACT_TYPE_CODE_MONTHLY) {
                    items = this.ungroupByNomenclatureLotId(items)
                }

                for (let item of items) {
                    let nomenclature_lot_id = null
                    if (item.nomenclatureLot && item.nomenclatureLot.id !== "Без партии") {
                        nomenclature_lot_id = item.nomenclatureLot.id;
                    }
                    data.act.items.push({
                        id: item.exists ? item.id : null,
                        nomenclature_id: item.nomenclature ? item.nomenclature.id : null,
                        nomenclature_lot_id,
                        expiration_date: item.expirationDate,
                        manufacture_date: item.manufactureDate,
                        count: item.count || 0,
                        system_count: item.system_count,
                    });
                }

                return data;
            },
            ungroupByNomenclatureLotId(items) {
                items = JSON.parse(JSON.stringify(items))

                const result = []

                for (let item of items) {
                    let count = parseFloat(item.count)
                    const nomenclatureLots = item.nomenclatureLots
                        .sort((a, b) => moment(b.manufacture_date) - moment(a.manufacture_date))

                    if (nomenclatureLots.length === 0) {
                        result.push(item)
                        continue
                    }

                    if (nomenclatureLots.length === 1) {
                        result.push(item)
                        continue
                    }

                    const index = result.length

                    for (let nomenclatureLot of nomenclatureLots) {
                        if (nomenclatureLot.systemCount < count) {
                            count -= parseFloat(nomenclatureLot.systemCount)
                            const newItem = JSON.parse(JSON.stringify(item))
                            newItem.count = parseFloat(nomenclatureLot.systemCount)
                            newItem.system_count = nomenclatureLot.systemCount
                            newItem.nomenclatureLot = nomenclatureLot
                            result.push(newItem)
                        } else {
                            const newItem = JSON.parse(JSON.stringify(item))
                            newItem.count = parseFloat(count.toFixed(3))
                            newItem.system_count = nomenclatureLot.systemCount
                            newItem.nomenclatureLot = nomenclatureLot
                            result.push(newItem)
                            count = 0
                        }
                    }

                    if (count > 0) {
                        result[index].count = parseFloat((parseFloat(result[index].count) + count).toFixed(3))
                    }
                }

                return result
            },
            isItemValid(item) {
                if (!item.nomenclature) {
                    return false;
                }

                if (!item.nomenclatureLot) {
                    return false;
                }

                if (item.nomenclatureLot && item.nomenclatureLot.id === "Без партии") {
                    if (!item.expirationDate) {
                        return  false;
                    }

                    if (!item.manufactureDate) {
                        return false;
                    }

                    if (moment(item.manufactureDate) > moment(item.expirationDate)) {
                        return false;
                    }
                }

                if (item.count.toString() === "") {
                    return false;
                }

                return true;
            },
            onBarcodeScanned(data) {
                if (!this.act || !this.act.storage) {

                    return;
                }

                this.getItemByBarcode(data, this.addItem, () => {}, this.act.items, this.act.storage.id);
            },
            confirmAct() {
                this.loading = true;
                this.$http.post(INVENTORY_ACTS_ENDPOINT + `/${this.act.id}/confirm`)
                    .then(response => {
                        this.$toast.success("Акт инвентаризации успешно подтвержден");
                        this.$router.push({name: 'InventoryActList'});
                    }).catch(response => {
                        ErrorsBag.discard();
                        ErrorsBag.fill(response.body);
                        this.$toast.error('Есть ошибки! Ознакомьтесь со списком вверху страницы');
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            cancelAct() {
                this.loading = true;
                this.$http.post(INVENTORY_ACTS_ENDPOINT + `/${this.act.id}/cancel`)
                    .then(response => {
                        this.$toast.success("Акт инвентаризации успешно отменен");
                        this.$router.push({name: 'InventoryActList'});
                    }).catch(response => {
                        ErrorsBag.discard();
                        ErrorsBag.fill(response.body);
                        this.$toast.error('Есть ошибки! Ознакомьтесь со списком вверху страницы');
                        window.scrollTo({top: 0, behavior: 'smooth'});
                    }).finally(() => {
                        this.loading = false;
                    });
            },
            confirm(message) {
                return window.confirm(message);
            },
            formatDate(format, date) {
                return moment(date).format(format);
            },
            hasError(key) {
                let result = Object.keys(this.errors).find((value, index) => {
                    return value.includes(key);
                });

                return !!result;
            },
            removeError(key) {
                Object.keys(this.errors).map(k => {
                    if (k.includes(key)) {
                        delete this.errors[k];
                    }
                })
            },
            firstErrorMessage(key) {
                let errorKey = Object.keys(this.errors).find((value, index) => {
                    return value.includes(key);
                });

                return this.errors[errorKey][0];
            },
            review() {
                this.$http.put(this.getBaseUrl() + `/${this.act.id}/review`)
                    .then(response => {
                        this.act.revisions = response.data.revisions;
                    });
            },
            isItemNomenclatureDisabled(item) {
                return this.disabled || item.blocked;
            },
            isItemLotDisabled(item) {
                if (!item.nomenclature) {
                    return true;
                }
                if (this.disabled) {
                    return true;
                }
                if (!!item.nomenclatureLot) {
                    return item.blocked;
                }
                return false;
            },
            isItemManufactureDateDisabled(item) {
                if (this.isItemLotDisabled(item)) {
                    return true;
                }
                if (this.disabled) {
                    return true;
                }
                if (!item.nomenclatureLot) {
                    return true;
                }
                if (item.nomenclatureLot && item.nomenclatureLot.id !== "Без партии") {
                    return true;
                }
                return false;
            },
            isItemExpirationDateDisabled(item) {
                if (this.isItemLotDisabled(item)) {
                    return true;
                }
                if (this.disabled) {
                    return true;
                }
                if (!item.nomenclatureLot) {
                    return true;
                }
                if (item.nomenclatureLot && item.nomenclatureLot.id !== "Без партии") {
                    return true;
                }
                return false;
            },
            isItemDeletable(item) {
                return !this.disabled && !item.blocked;
            },
            factCountFormatter(value) {
                value = value
                    .toString()
                    .replace(",", ".")
                    .replace(/[^\d.\-]/g, "");

                let result = ""
                for (var i = 0; i < value.length; i++) {
                    if (value[i] !== ".") {
                        result += value[i];
                    } else if (!result.includes(".")) {
                        result += value[i];
                    }
                }

                return result;
            },
            factCountFocused(value) {
                if (value.toString() === "0") {
                    return "";
                }
                return value;
            },
            factCountBlured(value) {
                if (value.toString() === "") {
                    return 0;
                }
                return value.toString().replace(/\.$/g, "")
            }
        },
    }
</script>

<style>
    .inventorying-act-form .white {
        color: white !important;
    }
    .inventorying-act-form .card.bg-secondary .text-muted {
        color: #fff !important;
    }

    .has-errors {
        color: red;
    }

    .card-body.body {
        padding: 0 !important;
    }
</style>

<style>
    table#inventory-act-items .blocked {
        opacity: unset !important;
    }

    table#inventory-act-items {
        margin-bottom: 0 !important;
    }

    table#inventory-act-items th {
        top: 0;
        z-index: 1;
        color: white;
        position: sticky;
        font-weight: bold;
        font-size: 0.875rem;
        line-height: 1.5;
        padding-top: 12px;
        padding-bottom: 12px;
        border: 1px solid #c8ced3;
        vertical-align: middle !important;
        background-color: rgb(35, 40, 44);
    }

    table#inventory-act-items td {
        padding: 0 !important;
        border: 1px solid #c8ced3;
        background: #e4e7ea !important;
        vertical-align: middle !important;
    }

    table#inventory-act-items tr.blocked td {
        padding: 0 12px !important;
        background: #e4e7ea !important;
    }

    table#inventory-act-items td select {
        height: 50px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin: 0 !important;
        width: 100% !important;
        border: none !important;
        outline: none !important;
        color: black !important;
        padding:  0 12px !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        appearance: none !important;
        outline-offset: 0 !important;
        line-height: 50px !important;
    }

    table#inventory-act-items td select:focus {
        outline: none !important;
        box-shadow: inset 0 0 3px #8ad4ee !important;
    }

    table#inventory-act-items td select:disabled {
        background: #e4e7ea !important;
    }

    table#inventory-act-items td input {
        height: 50px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
        border: none !important;
        outline: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
        outline-offset: 0 !important;
        line-height: 50px !important;
    }

    table#inventory-act-items td input:focus {
        outline: none !important;
        box-shadow: inset 0 0 3px #8ad4ee !important;
    }

    table#inventory-act-items td input:disabled {
        background: #e4e7ea !important;
    }

    table#inventory-act-items .multiselect__select {
        display: none !important;
    }

    table#inventory-act-items .multiselect__tags {
        padding: 0 !important;
        border: none !important;
        height: 50px !important;
        border-radius: 0 !important;
    }

    table#inventory-act-items .multiselect--disabled .multiselect__tags {
        background: #e4e7ea !important;
    }

    table#inventory-act-items .multiselect__content-wrapper {
        width: auto !important;
        min-width: 100% !important;
        border-radius: 0 !important;
    }

    table#inventory-act-items span.multiselect__single {
        height: 50px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        border-radius: 0 !important;
        line-height: 50px !important;
        padding-left: 12px !important;
    }

    table#inventory-act-items span.multiselect__placeholder {
        margin: 0 !important;
        height: 50px !important;
        padding: 0 12px !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 50px !important;
    }

    table#inventory-act-items .nomenclature input {
        text-align: left !important;
        padding-left: 12px !important;
    }

    table#inventory-act-items .nomenclature {
        width: 33% !important;
    }

    table#inventory-act-items .nomenclature-lot {
        width: 11% !important;
    }

    table#inventory-act-items .nomenclature-lot.blocked {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    table#inventory-act-items td.nomenclature-lot.empty {
        color: #999 !important;
    }

    table#inventory-act-items td.nomenclature-lot.blocked {
        padding: 0 12px !important;
    }

    table#inventory-act-items .date-time-picker {
        margin: 0 !important;
        height: 100% !important;
        box-sizing: border-box;
    }

    table#inventory-act-items .manufacture-date {
        text-align: center;
    }

    table#inventory-act-items td.manufacture-date.empty {
        color: #999 !important;
    }

    table#inventory-act-items .expiration-date {
        text-align: center;
    }

    table#inventory-act-items td.expiration-date.empty {
        color: #999 !important;
    }

    table#inventory-act-items .fact-count {
        text-align: right;
        white-space: nowrap !important;
    }

    table#inventory-act-items td.fact-count {
        padding: 0 !important;
        font-size: 0 !important;
    }

    table#inventory-act-items tr.blocked td.fact-count {
        padding: 0 !important;
    }

    table#inventory-act-items td.fact-count button {
        margin: 0 !important;
        width: 30% !important;
        height: 50px !important;
        font-size: 14px !important;
        padding-top: 7px !important;
        border-radius: 0 !important;
        display: inline-block !important;
        vertical-align: middle !important;
        border-right: 1px solid #c8ced3 !important;
        border-bottom: none !important;
        border-left: none !important;
        border-top: none !important;
    }

    table#inventory-act-items td.fact-count button:focus {
        box-shadow: none !important;
    }

    table#inventory-act-items td.fact-count button:active {
        box-shadow: inset 0 0 3px #8ad4ee !important;
    }

    table#inventory-act-items td.fact-count input {
        width: 70% !important;
        font-size: 14px !important;
        text-align: right !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
        vertical-align: top !important;
        display: inline-block !important;
    }

    table#inventory-act-items .report-count {
        text-align: right;
    }

    table#inventory-act-items td.report-count {
        text-align: right !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
        background: #e4e7ea !important;
    }

    table#inventory-act-items .measure-unit {
        width: 80px !important;
        text-align: center;
    }

    table#inventory-act-items td.measure-unit {
        vertical-align: middle !important;
        background: #e4e7ea !important;
    }

    table#inventory-act-items .actions {
        width: 60px !important;
        text-align: center;
    }

    table#inventory-act-items td.actions {
        background: #fff !important;
        vertical-align: middle !important;
    }

    table#inventory-act-items td.actions.actions-disabled {
        background: #e4e7ea !important;
    }
</style>
