<template>
    <multiselect
        label="name"
        track-by="id"
        selectLabel
        deselectLabel
        selectedLabel
        @select="onSelect"
        :options="options"
        :searchable="false"
        :allowEmpty="false"
        :disabled="disabled"
        v-model="currentValue"
        :preselectFirst="true"
        :placeholder="placeholder"
    >
        <template slot="singleLabel" slot-scope="{ option }">{{ option.id }}</template>
        <template slot="noOptions">Список пуст</template>
    </multiselect>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
    import Multiselect from "vue-multiselect"

    export default {
        components: {
            Multiselect
        },
        props: {
            disabled: {
                type: Boolean,
                default: false
            },
            availableItems: {
                type: Array,
                default: []
            },
            value: {},
            nomenclatureId: {},
            placeholder: {
                type: String,
                default: "Выберите партию"
            },
            allowWithoutLot: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            value() {
                this.setValue()
            },
            options() {
                this.setValue()
            }
        },
        computed: {
            options() {
                const options = this.availableItems.filter(lot => lot.nomenclature_id === this.nomenclatureId)
                if (this.allowWithoutLot) {
                    options.unshift({ id: "Без партии", name: "Без партии" })
                }
                return options
            }
        },
        data() {
            return {
                currentValue: null
            }
        },
        methods: {
            onSelect(selectedOption) {
                this.$emit("input", selectedOption)
            },
            setValue() {
                if (this.value) {
                    let item = this.options.find(lot => lot.id === this.value.id)
                    this.currentValue = item ? item : this.value
                } else {
                    this.currentValue = null
                }
            }
        },
        mounted() {
            this.setValue()
        }
    }
</script>
